import React from 'react';
import './App.css';

const backgroundImage = process.env.PUBLIC_URL + '/background.jpg';

function App() {
  return (
    <div className="App" style={{backgroundImage: `url(${backgroundImage})`, height: '100vh', backgroundSize: 'cover'}}>
      <header className="App-header">
        <p>Hello, World!!</p>
      </header>
    </div>
  );
}

export default App;
